<template>
  <router-view></router-view>
</template>

<script>
import { mapGetters } from 'vuex'
import router from '../router'

export default {
  name: 'Index',
  mounted() {
    if(this.$route.meta?.requiresAuth){
      if(!this.user.data){
        router.push('/start')
      }
      else if(this.$route.meta?.admin){
        if (!this.user.data.admin) {
          router.push('/user')
        }
      }
      else if(this.$route.meta?.verein){
        if (!this.user.data.verein) {
          router.push('/user')
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
  },
}
</script>
